<script setup lang="ts">
import { SwiperOptions } from 'swiper/types';
import TopBanner from './TopBanner.vue';

export type TopBannerEntry = {
  content: string;
  title: string;
  countdown_date?: Date;
  hide_banner_date?: Date;
  timer_expired_content?: string;
  timer_expired_link?: string;
  link: string;
} & typeof TopBanner;

const props = defineProps({
  topBanner: {
    type: Object as () => typeof TopBanner,
    default: () => ({}),
  },
  autoPlayTime: {
    type: Number,
    default: 0,
  },
});

const banners = computed(() => [...(props.topBanner?.edges || [])]);
const backgroundColor = computed(() => {
  return banners?.value[0]?.node?.customize?.background_color;
});
const isMultiple = computed(() => banners.value.length > 1);
const swiperOptions: SwiperOptions = {
  navigation: {
    nextEl: '.tbanner-btn-next',
    prevEl: '.tbanner-btn-prev',
  },
  a11y: {
    enabled: true,
  },
  autoplay:
    isMultiple.value && props.autoPlayTime > 0
      ? {
          delay: props.autoPlayTime * 1000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }
      : false,
  effect: 'slide',
  slidesPerView: 1,
  loop: true,
  speed: 1000,
};
</script>

<template>
  <div
    :class="['top-banner-wrapper', { 'v-hidden': isMultiple }]"
    :style="backgroundColor ? `background-color: ${backgroundColor.value}` : ''"
  >
    <WrapperSwiper
      v-if="banners?.length > 1"
      :class="[{ 'tbanner-swiper-container': isMultiple }]"
      :swiper-options="swiperOptions"
    >
      <swiper-slide v-for="(bannerEdge, i) in banners" :key="`${i}-${bannerEdge.node.content}`">
        <TopBanner :top-banner="bannerEdge.node" />
      </swiper-slide>
    </WrapperSwiper>
    <template v-if="isMultiple">
      <div class="tbanner-btn-prev swiper-desk-and-tab-only">
        <ArrowLeftSLine />
      </div>
      <div class="tbanner-btn-next swiper-desk-and-tab-only">
        <ArrowRightSLine />
      </div>
    </template>
    <TopBanner v-else-if="banners?.length === 1" :top-banner="banners?.[0]?.node" />
  </div>
</template>

<style lang="scss" scoped>
swiper-container {
  @include local-mixins.mobile {
    width: 80%;
  }
  @include local-mixins.tablet {
    width: 70%;
  }
  @include local-mixins.desktop {
    width: 55%;
  }
}
.top-banner-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $color-neutral-cool-900;

  @include local-mixins.desktop_and_tablet {
    overflow: hidden;
    list-style: none;
    position: sticky;
    top: 0px;
    padding: 0;
    z-index: 3;
  }
}
.v-hidden {
  visibility: hidden;
}
.fade-up {
  animation: fadeUp 0.5s ease-out forwards;
}

@keyframes fadeUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
.tbanner-swiper-container {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  list-style: none;
  position: sticky;
  top: 0px;
  padding: 0;
  z-index: 3;
  background-color: #000000;
  height: 40px;
  @include local-mixins.tablet_and_mobile {
    height: 70px;
  }

  @include local-mixins.mobile {
    :deep(p) {
      font-size: 16px;
      line-height: 1.5rem;
    }
    :deep(span) {
      font-size: 16px;
      line-height: 1.5rem;
    }
    :deep(a) {
      font-size: 16px;
      line-height: 1.5rem;
    }
  }

  @include local-mixins.tablet {
    :deep(p) {
      font-size: 14px;
    }

    :deep(span) {
      font-size: 14px;
    }

    :deep(a) {
      font-size: 14px;
    }
  }
}

.tbanner-swiper-pagination {
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex !important;
  justify-content: center;
  height: 0.5rem;
  width: 100%;

  :deep(.tbanner-pag-bullet) {
    background-color: gray;
    height: 0.25rem;
    width: 1rem;
    display: inline-block;
    border-radius: 25%;
    cursor: pointer;
    opacity: 1;
    margin: 0 0.25rem;

    &.tbanner-pag-current {
      background-color: white;
      cursor: initial;
    }
  }

  span {
    height: 100%;
  }

  // @include local-mixins.desktop_and_tablet {
  //   display: none !important;
  // }
}

.swiper-navs {
  height: 100%;
  position: absolute;
  top: 0;
}

.tbanner-btn-next,
.tbanner-btn-prev {
  // display: none !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: none;
  position: absolute;
  z-index: 10;
  top: 0;
  height: 100%;

  @include local-mixins.tablet {
    margin: 0 10%;
  }

  @include local-mixins.desktop {
    margin: 0 20%;
  }

  :deep(svg) {
    fill: $color-neutral-white;
  }

  &.swiper-button-disabled {
    :deep(svg) {
      fill: $color-neutral-cool-300;
    }
  }

  &:after {
    content: none;
  }

  // @include local-mixins.desktop_and_tablet {
  //   display: flex !important;
  // }
}
.tbanner-btn-prev {
  margin-right: 1rem;
  left: 1rem;
  right: auto;
  z-index: 3;
}
.tbanner-btn-next {
  margin-left: 1rem;
  right: 1rem;
  left: auto;
  z-index: 3;
}

swiper-container::part(bullet),
swiper-container::part(bullet-active) {
  opacity: 50%;
  margin-top: 10px;
}
swiper-container::part(bullet-active) {
  background-color: $color-primary-500;
}
swiper-container::part(bullet) {
  background-color: $color-neutral-cool-600;
}
</style>
